import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout/layout";

export default function ContratarDictamen() {
    return (
        <React.Fragment>
            <SEO />
            <Layout pageId="buy-dictamen">
                <h1>Contratar dictamen</h1>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <h3>- POR HACER -</h3>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
            </Layout>
        </React.Fragment>
    );
}
